<template>
  <div class="coupon-box">
    <s-header :name="'我的券包'"></s-header>
    <van-empty 
      image="https://img01.yzcdn.cn/vant/coupon-empty.png" 
      description="暂无卡券，敬请期待！" 
    />
  </div>
</template>
<script>
  import sHeader from '@/components/SimpleHeader'
  export default {
    name: 'coupon',
    data() {
      return {

      }
    },
    components: {
      sHeader,
    },
  }
</script>
<style lang="less" scoped>
  .coupon-box {
    .van-empty {
      margin-top: 44px;
    }
  }
</style>