import axios from '@/utils/request'

// export function login(params) {
//   return axios.post('/customerapp/auth/wxlogin', params);
// }

export function loginCallback(params) {
  return axios({
    url: '/customerapp/auth/wxcallback',
    method: 'post',
    params: params
  })
}