<template>
  <div class="my-order">
    <s-header :name="'我的订单'" :back="'/user'"></s-header>
    <van-tabs @change="onChangeTab" :color="'#1baeae'" :title-active-color="'#1baeae'" class="order-tab" v-model="queryParams.orderStatus">
      <van-tab title="全部" name="ALL"></van-tab>
      <van-tab title="服务中" name="401"></van-tab>
      <van-tab title="待确认" name="501"></van-tab>
      <van-tab title="待评价" name="601"></van-tab>
      <van-tab title="已完成" name="701"></van-tab>
    </van-tabs>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="order-list-refresh">
      <div v-if='noData'>暂无数据</div>
      <template v-else>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          @offset="20">
          <div v-for="(item, index) in list" :key="index" class="order-item-box" @click="goTo(item.id)">
            <div class="order-item-header">
              <span>订单时间：{{ item.advancePayTime }}</span>
              <span><van-icon name="arrow" /></span>
            </div>
            <div class="order-item-content">
              <div>
                <p>{{item.productName}} ({{item.attributeName}}) 1件服务</p>
                <p>{{ item.createTime }}</p>
              </div>
              <div class="payitem"> 
                <p class="price">￥ {{item.advancePayPrice}}</p>
                <p class="state" v-if="item.orderStatus == '101'">预付款</p>
                <p class="state" v-if="item.orderStatus == '201'">待接单</p>
                <p class="state" v-if="item.orderStatus == '301'">待服务</p>
                <p class="state" v-if="item.orderStatus == '401'">服务中</p>
                <p class="state" v-if="item.orderStatus == '501'">待确认</p>
                <p class="state" v-if="item.orderStatus == '601'">待评价</p>
                <p class="state" v-if="item.orderStatus == '701'">已完成</p>
              </div>
            </div>
            <!-- <div class="order-item-btn" v-if="item.orderStatus == '101'">
              <van-button plain size="mini" @click.stop="advancePay(item.orderId, item.orderStatus)">预 付 款</van-button>
            </div> -->
            <!-- <div class="order-item-btn" v-if="item.orderStatus == '501'">
              <van-button plain size="mini" @click.stop="restPay(item.orderId, item.orderStatus)">付 款</van-button>
            </div> -->
          </div>
        </van-list>
      </template>
    </van-pull-refresh>
    <NavBar></NavBar>
  </div>
</template>
<script>
  import sHeader from '@/components/SimpleHeader'
  import NavBar from '@/components/NavBar'
  import { listOrder,payAdv,payRest } from '@/api/order'
  export default {
    name: 'Order',
    data() {
      return {
        loading: false,
        finished: false,
        refreshing: false,
        list: [],
        queryParams: {  // 分页数据
          pageSize: 6,  // 每页几条
          pageNum: 1,   // 第几页
          orderStatus: 'ALL',    // 初始化当前选中标识
        },
        total: 0, // 总条数
        pages: 0, // 总共几页
        noData: false,
        payInfo: {},
        htm: {}
      }
    },
    components: {
      sHeader,
      NavBar
    },
    watch: {
      $route() {
        alert('33')
        if (this.$route.query.orderStatus) {
          // 重新加载一次created或者mounted钩子中的渲染的数据
          this.queryParams.orderStatus = this.$route.query.orderStatus
          this.init()
        }
      },
    },
    created() {
      if(this.$route.query.orderStatus != undefined) {
        this.queryParams.orderStatus = this.$route.query.orderStatus
        this.init()
      } else {
        this.init()
      }
    },
    methods: {
      onChangeTab(name, title) {
        this.queryParams.orderStatus = name
        this.backtop()
        this.list = []
        this.queryParams.pageNum = 1
        this.pages = 0
        this.finished = false
        this.init()
      },
      init() {
        listOrder(this.queryParams).then(res => {
          this.list = this.list.concat(res.rows)
          this.total = res.total
          this.pages = res.pages

          this.refreshed = false
          this.refreshing = false
          this.loading = false
          if (this.list.length >= res.total) this.finished = true
          else this.finished = false
        })
      },
      onLoad() {
        setTimeout(() => {
          if (this.refreshing) {
            this.list = [];
            this.refreshing = false;
          }
          if (!this.refreshing && this.list.length < this.total) {
            this.queryParams.pageNum = this.queryParams.pageNum + 1
            this.loading = false;  
            this.init()
          }
        }, 3000)
      },
      onRefresh() {
        setTimeout(() => {
          this.refreshing = true  
          this.loading = true
          this.reset()
        }, 1500);
      },
      reset() {
        this.list = []
        this.queryParams.pageNum = 1
        this.finished = false
        this.init()
      },
      // 预付款
      advancePay(id, status) {
        // payAdv(id).then(res => {
        //   this.payInfo.appId = res.data.appId
        //   this.payInfo.timeStamp = res.data.timeStamp
        //   this.payInfo.nonceStr = res.data.nonceStr
        //   this.payInfo.package = res.data.package
        //   this.payInfo.signType = res.data.signType
        //   this.payInfo.paySign = res.data.paySign
        //   if (typeof WeixinJSBridge == "undefined") {
        //       if (document.addEventListener) {
        //           document.addEventListener('WeixinJSBridgeReady',
        //               this.advBridgeReady, false);
        //       } else if (document.attachEvent) {
        //           document.attachEvent('WeixinJSBridgeReady',
        //               this.advBridgeReady);
        //           document.attachEvent('onWeixinJSBridgeReady',
        //               this.advBridgeReady);
        //       }
        //       alert("请在微信上进行支付操作！");
        //       this.advBridgeReady();
        //   } else {
        //       this.advBridgeReady();
        //   }
        // })
      },
      // 尾款
      restPay(id, status) {
        // payRest(id).then(res => {
        //   this.payInfo.appId = res.data.appId
        //   this.payInfo.timeStamp = res.data.timeStamp
        //   this.payInfo.nonceStr = res.data.nonceStr
        //   this.payInfo.package = res.data.package
        //   this.payInfo.signType = res.data.signType
        //   this.payInfo.paySign = res.data.paySign
        //   if (typeof WeixinJSBridge == "undefined") {
        //       if (document.addEventListener) {
        //           document.addEventListener('WeixinJSBridgeReady',
        //               this.restBridgeReady, false);
        //       } else if (document.attachEvent) {
        //           document.attachEvent('WeixinJSBridgeReady',
        //               this.restBridgeReady);
        //           document.attachEvent('onWeixinJSBridgeReady',
        //               this.restBridgeReady);
        //       }
        //       alert("请在微信上进行支付操作！");
        //       this.restBridgeReady();
        //   } else {
        //       this.restBridgeReady();
        //   }
        // })
      },
      //向微信发起支付预付款请求
      advBridgeReady() {
        WeixinJSBridge.invoke( 'getBrandWCPayRequest', {
            "appId": this.payInfo.appId,           //公众号名称,由商户传入
            "timeStamp": this.payInfo.timeStamp,   //时间戳,自1970年以来的秒数
            "nonceStr": this.payInfo.nonceStr,     //随机串
            "package": this.payInfo.package,
            "signType": this.payInfo.signType,     //微信签名方式：
            "paySign": this.payInfo.paySign        //微信签名
        }, function(res){
            if(res.err_msg == "get_brand_wcpay_request:ok" ) {
              //支付成功后跳转的页面
              window.location.href = 'http://tiantian.nat300.top/order?orderStatus=DFW'
            }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
              // 取消支付
              window.location.href = 'http://tiantian.nat300.top/order?orderStatus=101'
            }else if(res.err_msg == "get_brand_wcpay_request:fail"){
              // alert('支付失败');
              window.location.href = 'http://tiantian.nat300.top/order?orderStatus=101'
              WeixinJSBridge.call('closeWindow');
            } //使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok,但并不保证它绝对可靠。
        });
      },
      //向微信发起支付尾款请求
      restBridgeReady() {
        WeixinJSBridge.invoke( 'getBrandWCPayRequest', {
            "appId": this.payInfo.appId,           //公众号名称,由商户传入
            "timeStamp": this.payInfo.timeStamp,   //时间戳,自1970年以来的秒数
            "nonceStr": this.payInfo.nonceStr,     //随机串
            "package": this.payInfo.package,
            "signType": this.payInfo.signType,     //微信签名方式：
            "paySign": this.payInfo.paySign        //微信签名
        }, function(res){
            if(res.err_msg == "get_brand_wcpay_request:ok" ) {
              //支付成功后跳转的页面
              window.location.href = 'http://tiantian.nat300.top/order?orderStatus=601'
            }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
              // 取消支付
              window.location.href = 'http://tiantian.nat300.top/order?orderStatus=501'
            }else if(res.err_msg == "get_brand_wcpay_request:fail"){
              // alert('支付失败');
              WeixinJSBridge.call('closeWindow');
              window.location.href = 'http://tiantian.nat300.top/order?orderStatus=501'
            } //使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok,但并不保证它绝对可靠。
        });
      },
      backtop() {
        let timer = setInterval(() => {
            let top = window.pageYOffset
                || document.documentElement.scrollTop
                || document.body.scrollTop;
            if (0 >= top) {
                clearInterval(timer);
                return;
            }
            let scrollTop = top - Math.floor(top / 5);
            scrollTop = 5 >= scrollTop ? 0 : scrollTop;
            document.documentElement.scrollTop = document.body.scrollTop = scrollTop;
        }, 20);
      },
      goTo(id) {
        this.$router.push({ path: `order-detail?id=${id}` })
      },
      onAgainOrder() {}
    },
    
  }
</script>
<style lang="less">
  @primary: #1baeae;
  @orange: #FF6B01;
  @gray: #f5f5f5;
  .my-order {
    .order-tab {
      position: fixed;
      z-index: 2;
      top: 44px;
      width: 100%;
    }
    .order-list-refresh {
      margin-top: 88px;
      .van-list {
        min-height: calc(100vh - 44px);
        background: @gray;
        padding: 10px;
        .van-list__error-text, .van-list__finished-text, .van-list__loading {
          padding-bottom: 80px !important;
        }
        .order-item-box {
          background-color: #fff;
          border-radius: 5px;
          margin-bottom: 10px;
          .order-item-header {
            padding: 15px 0 10px;
            margin: 0 10px;
            display: flex;
            font-size: 16px;
            border-bottom: 1px solid rgb(226, 225, 225);
          }
          .order-item-content {
            background-color: #fff;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            margin: 10px 10px 0px;
            padding: 0 0 8px;
            display: flex;
            justify-content: space-between;
            .payitem {
              width: 20%;
              text-align: center;
            }
            .price {
              color: #999;
              font-size: 15px;
            }
            .state {
              color: #FF6B01 !important;
            }
            p {
              margin: 5px;
              font-size: 14px;
            }
            p:last-child {
              color: #999;
              font-size: 12px;
            }
          }
          .order-item-btn {
            padding: 0 10px 10px;
            text-align: right;
            margin: 0 10px 0;
            .van-button {
              color: @orange;
              border: 1px solid @orange;
              width: 100%;
            }
          }
          .again-list {
            text-align: center;
            height: 40px;
            line-height: 40px;
            border-top: 1px solid rgb(226, 225, 225);
          }
        }
      }
    }
  }  
</style>