import request from '@/utils/request'

export function listAddress() {
  return request({
    url: '/customerapp/customerAddress/selectList',
    method: 'post'
  }) 
}

export function addAddress(query) {
  return request({
    url: '/customerapp/customerAddress/insertSelective',
    method: 'post',
    params: query
  })
}

export function editAddress(query) {
  return request({
    url: '/customerapp/customerAddress/updateByPrimaryKeySelective',
    method: 'post',
    params: query
  })
}

export function delAddress(id) {
  return request({
    url: '/customerapp/customerAddress/deleteByPrimaryKey?addressId=' + id,
    method: 'post'
  })
}

export function getDetail(id) {
  return request({
    url: '/customerapp/customerAddress/selectByPrimaryKey?addressId=' + id,
    method: 'get',
  })
}