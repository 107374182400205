<template>
  <div class="user-box">
    <s-header :name="'我的'"></s-header>
    <NavBar></NavBar>
    <div class="user-info">
      <div class="info">
        <img src="//s.weituibao.com/1583583975067/user-graduate%20(1).png"/>
        <div class="user-desc">
          <span>我家口腔</span>
          <span class="integ">积分：0   余额：0</span>
        </div>
        <div class="user-set" @click="goTo('setting')">
          <van-icon name="setting-o" />
          <!-- <van-button type="default" size="mini">余额充值</van-button> -->
        </div>
      </div>
    </div>
    <ul class="user-list">
      <!-- <li @click="goTo('coupon')">
        <span><van-icon name="coupon-o" />卡券</span>
        <van-icon name="arrow" />
      </li> -->
      <li @click="goTo('evaluate')">
        <span><van-icon name="comment-o" />评价</span>
        <van-icon name="arrow" />
      </li>
      <!-- <li @click="goTo('address?from=mine')">
        <span><van-icon name="location-o" />位置</span>
        <van-icon name="arrow" />
      </li> -->
      <li @click="goTo('about')">
        <span><van-icon name="question-o" />帮助</span>
        <van-icon name="arrow" />
      </li>
    </ul>
  </div>
</template>
<script>
  import sHeader from '@/components/SimpleHeader'
  import NavBar from '@/components/NavBar'
  import { getUserInfo } from '../api/order'
  export default {
    name: 'user',
    data() {
      return {

      }
    },
    components: {
      sHeader,
      NavBar
    },
    async mounted() {
      // const { data } = await getUserInfo()
      // this.user = data
      // console.log(data);
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      goTo(r) {
        this.$router.push({ path: r })
      }
    }
  }
</script>
<style lang="less" scoped>
  @primary: #1baeae;
  @orange: #FF6B01;
  @gray: #f5f5f5;
  .user-box {
    margin-top: 44px;
    .user-info {
      width: 94%;
      margin: 10px;
      height: 115px;
      background: linear-gradient(90deg, @primary, #51c7c7);
      box-shadow: 0 2px 5px #269090;
      border-radius: 6px;
      margin-top: 50px;
      .info {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 25px 20px;
        box-sizing: border-box;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin-top: 4px;
        }
        .user-desc {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          line-height: 20px;
          font-size: 14px;
          color: #fff;
          span {
            color: #fff;
            font-size: 16px;
            padding: 2px 0;
          }
          .integ {
            margin: 18px 0;
            font-size: 14px;
            color: rgba(255,255,255, 0.5);
          }
        }
        .user-set {
          width: 40%;
          i {
            margin-top: -5px;
            margin-left: 120px;
            color: #fff;
            position: relative;
          }
          button {
            position: absolute;
            margin-top: 40px;
            margin-left: 60px;
            border: 1px solid #fff;
            background-color: transparent;
            color: #fff;
            border-radius: 20px;
            padding: 0 6px;
          }
        }
        .account-setting {
          position: absolute;
          top: 10px;
          right: 20px;
          font-size: 13px;
          color: #fff;
          .van-icon-setting-o {
            font-size: 16px;
            vertical-align: -3px;
            margin-right: 4px;
          }
        }
      }
    }
    .user-list {
      padding: 0 20px;
      margin-top: 20px;
      li {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        span {
          i {
            top: 2px;
            margin-right: 4px;
          }
        }
        .van-icon-arrow {
          margin-top: 13px;
        }
      }
    }
  }
</style>