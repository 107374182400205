import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {  //存放的键值对就是所要管理的状态
    cartItem: {}  // 下单的产品信息 
  },
  mutations: { 
    cartItem(state, msg) {
      state.cartItem = msg;
    }
  },
  actions: {
  },
  modules: {
  }
})