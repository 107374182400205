<template>
  <div class="address-box">
    <s-header :name="'地址管理'"></s-header>
    <div class="address-item">
      <!-- <van-address-list
        v-if="from != 'mine'"
        v-model="chosenAddressId"
        :list="list"
        disabled-text="以下地址超出配送范围"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
        @select="select"
      /> -->
      <van-address-list
        v-model="chosenAddressId"
        :list="list"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
        @select="select"
      />
    </div>
  </div>
</template>
<script>
  import sHeader from '@/components/SimpleHeader'
  import {listAddress} from '@/api/address'
  export default {
    name: 'addr',
    data() {
      return {
        chosenAddressId: '1',
        from: this.$route.query.from,
        radio: '1',
        list: []
      }
    },
    components: {
      sHeader
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        listAddress().then(res => {
          const _data = res.data
          const newlist = []
          for(let i of _data) {
            let _defaultC = false;
            if(i.addressDefault == 0) { 
              _defaultC = true 
              this.chosenAddressId = i.addressId
            } else { 
              _defaultC = false 
            }
            let arr = {
              id: i.addressId,
              name: i.consigneeName,
              tel: i.consigneeMobile,
              address: i.consigneeProvinceName+' '+i.consigneeCityName+' '+i.consigneeRegionName+' '+i.consigneeDetailAddress,
              isDefault: _defaultC
            }
            newlist.push(arr)
          }
          console.log(newlist);
          this.list = newlist
        })
      },
      onAdd() {
        this.$router.push({ path: `address-edit?type=add&from=${this.from}` })
        // this.$router.push({ path: `map?type=add&from=${this.from}` })
      },
      onEdit(item, index) {
        this.$router.push({ path: `address-edit?type=edit&addressId=${item.id}&from=${this.from}` })
      },
      select(item, index) {
        const _params = JSON.stringify(item)
        if(this.from == 'order') {
          localStorage.setItem('checkAddr', _params)
          this.$router.push({ path: `create-order?from=${this.from}` })
        }
      },
    }
  }
</script>
<style lang="less">
  @import "../assets/css/base.less";
  .address-box {
    // .van-radio__icon {
    //   display: none;
    // }
    .address-item {
      margin-top: 44px;
      .van-tag--danger {
        background-color: @primary;
      }
      .van-address-item {
        .van-radio__icon--checked {
          .van-icon {
            background-color: @primary;
            border-color: @primary;
          }     
        }
      }
      .van-address-list__bottom {
        max-width: 750px;/*no*/
        margin: 0 auto;
        left: auto;
        box-shadow: 0px -4px 5px rgba(0, 0, 0, .1);
        .van-button {
          background: @primary;
          border-color: @primary;
        }
      }
      
    }
  }
</style>