import axios from '@/utils/request'

// export function login(params) {
//   return axios.post('/customerapp/auth/wxlogin', params);
// }

export function login(params) {
  return axios({
    url: '/agentapp/author/login',
    method: 'post',
    params: params
  })
}