<template>
  <header class="simple-header">
    <van-icon name="arrow-left" @click="goBack"/>
    <div class="simple-header-name">{{ name }}</div>
    <van-icon name="ellipsis" />
  </header>
</template>
<script>
  export default {
    name: 'SimpleHeader',
    props: {
      name: {
        type: String,
        default: ''
      },
      back: {
        type: String,
        default: ''
      }
    },
    methods: {
      goBack() {
        if (!this.back) {
          this.$router.go(-1)
        } else {
          this.$router.push({ path: this.back })
        }
        // 向父组件发送请求
        this.$emit('callback')
      }
    }
  }
</script>
<style>
  .simple-header {
    position: fixed;
    top: 0;
    /* left: 0; */
    max-width: 750px; /*no*/
    z-index: 2000;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0 10px;
    box-sizing: border-box;
    color: #252525;
    background: #fff;
    border-bottom: 1px solid #dcdcdc;
    font-size: 14px; 
  }
  .simple-header .van-icon {
    height: 44px;
    line-height: 44px;
  }
  .simple-header .simple-header-name {
    font-size: 14px;
  }
</style>