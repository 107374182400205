<template>
  <div class="product-detail" v-cloak>
    <sHeader :name="'医生简介'"/>
    <div class="detail-content">
      <div class="product-info">
        <div class="product-title">{{detail.productName}}</div>
        <div class="product-desc">浏览999+     好评99+</div>
        <div class="product-price">
          <span>{{ detail.doctorName }}</span>
          <!-- <span>¥{{ detail.sellPrice }}</span> -->
          <!-- <span>库存203</span> -->
        </div>
      </div>
      <van-divider :style="divider">个人简介</van-divider>
      <div class="product-img">
        <div class="content" v-html="detail.doctorDesc"></div>
      </div>
      <van-divider :style="divider">承诺保障</van-divider>
      <div class="product-intro">
        <div class="content" v-html="desc"></div>
      </div>
      <!-- bar start -->
      <van-goods-action>
        <van-goods-action-icon icon="wap-home-o" text="首页" @click="onHome" />
        <!-- <p class="goods-price">金额：<span>¥{{detail.originalPrice == undefined ? 0 : detail.originalPrice}}元</span></p>
        <van-goods-action-button text="预约服务" @click="showPopup = true"/> 
        <van-goods-action-button text="提交" @click="onSubmit"/> -->
      </van-goods-action>
      <!-- bar end -->
      <!-- popup -->
      <van-popup v-model="showPopup" round position="bottom" :style="{ height: '45%' }" closeable 
                  close-icon="close" @click-close-icon="colClose">
        <div class="title">{{detail.productName}}</div>
        <div class="content">
          <div class="con-item">
            <span>规格</span>
            <van-row type="flex">
              <van-col v-for="item in varietyList" 
                       safe-area-inset-bottom="true" 
                       :key="item.attributeId" 
                       :value="item.attributeId"
                       :class="{active:varietyIndex === item.attributeId}"
                       @click="colClick(item)">
                {{item.attributeName + '￥' + item.paymentRange}}
              </van-col>
            </van-row>
          </div>
        </div>
        <!-- <div class="button">
          <van-button round block type="info" native-type="submit" @click="onSubmit">提交</van-button>
        </div> -->
      </van-popup>
    </div>
    
  </div>
</template>
<script>
  import sHeader from '@/components/SimpleHeader'
  import { getDetail } from '@/api/doctorDetail'
  import { Toast } from 'vant'
  export default {
    name: 'ProductDetail',
    data() {
      return {
        detail: {
          doctorName:"",
          doctorInfo:"",
          doctorDesc:"",
          goodsCarouselList: [],
        },
        divider: {
          padding: '0 16px',
          color: '#999',
          borderColor: '#999'
        },
        // 承诺保障
        desc: `<p>车接车送，专业服务；让您放心的口腔专家</p>   
              <p>费用一般都需要现场确认后进行报价，平台报价仅为检测的参考价，最终收费，以医院报价为准；</p>
              <p>*特别提醒：请把订单费用支付到平台，平台会为你保留7天并提供发票，并提供售后服务保障！</p>`,
        showPopup: false,
        // 报修
        varietyList: [],
        varietyIndex: 0,
        // 生成订单要素
        result: []    
      }
    },
    components: {
      sHeader
    },
    created() {
      this.init()
    },
    computed: {
      count () {
        return this.$store.state.cartCount
      }
    },
    methods: {
      init() {
        const _arr = this.$route.params
        const _id = _arr.doctorId
        getDetail(_id).then( res => {
          this.detail = res.data
          //this.varietyList = res.data.attributeList
        })
      },
      goBack() {
        this.$router.go(-1)
      },
      goTo() {
        this.$router.push({ path: '/cart' })
      },
      // go 首页
      onHome() {
        this.$router.push({ path: '/home' })
      },
      onAppoint() {

      },
      // 提交预约
      async onSubmit() {
        if(this.result.length === 0) {
          Toast({message: '请选择要预约服务！', position: 'top'});
          return
        }
        const params = JSON.stringify(this.result)
        localStorage.setItem('cartItem', params)
        // this.$store.commit('cartItem', params)
        this.$router.push({ path: `/create-order` })
      },
      onBuyClicked() {},
      onAddCartClicked() {},
      // 选择服务 规格
      colClick(item) {
        this.varietyIndex = item.attributeId
        this.detail.originalPrice = item.paymentRange
        this.showPopup = false
        this.result = {
          productName: this.detail.productName,
          introduction: this.detail.introduction,
          imgUrl: this.detail.imgUrl,
          serviceArr: item
        }
      },
      // 关闭弹窗
      colClose() {
        this.showPopup = false
      },
    },
    
  }
</script>
<style>
  .product-detail .detail-content {
    margin-top: 44px;
  }
  .product-detail .product-info {
    padding: 10px 10px 0;
  }
  .product-detail .product-title {
    font-size: 17px;
    color: #333;
  }
  .product-detail .product-desc {
    font-size: 13px;
    text-align: left;
    color: #999;
    padding: 8px 0;
  }
  .product-detail .product-price {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .product-detail .product-price span:nth-child(1) {
    color: #FF6B01;
    font-size: 22px;
    padding: 14px 0 8px;
  }
  .product-detail .product-img,.product-intro {
    padding: 0 20px;
  }
  .product-detail .product-img img{ 
    width: 100%;
  }
  .product-detail .product-intro {
    font-size: 14px;
    padding-bottom: 80px;
  }
  .product-detail .product-intro p:last-child {
    color: #FF6B01;
    padding-top: 20px;
  }
  .product-detail .van-goods-action {
    margin: 0 auto;
    max-width: 750px; /*no*/
    box-shadow: 0px -4px 5px rgba(0, 0, 0, .1);
  }
  .product-detail .goods-price {
    font-size: 14px;
    color: #333;
    width: 160px;
    text-align: right;
    margin-right: 2px;
  }
  .product-detail .goods-price span {
    color: #FF6B01;
  }
  .product-detail .van-goods-action-button--first {
    background-color: #FF6B01 !important;
  }
  .product-detail .van-goods-action-button {
    /* background: linear-gradient(to right, #0dc3c3, #1baeae); */
    background-color: #1baeae;
  }
  .product-detail .van-goods-action-button span {
      font-size: 15px;
      letter-spacing: 1px;
      color: #fff !important;
  }
  .product-detail .van-popup--round {
    overflow: hidden;
  }
  .product-detail .van-popup--round .title {
    height: 50px;
    line-height: 55px;
    text-align: center;
    font-size: 16px;
    color: #333;
    letter-spacing: 1px;
    position: fixed;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
  }
  .product-detail .van-popup--round .content::-webkit-scrollbar {
    display: none;
  }
  .product-detail .van-popup--round .content {
    padding: 50px 5px 70px;
    height: 100%;
    overflow-y: auto;
  }
  .product-detail .van-popup--round .con-item span {
    font-size: 10px;
    padding: 0 0 0 10px;
  }
  .product-detail .van-popup--round .con-item .van-row {
    font-size: 10px;
    padding-top: 10px;
  }
  .product-detail .van-popup--round .con-item .van-col {
    border: 1px solid #ccc;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin: 0 10px 10px;
    padding: 0 8px;
    border-radius: 4px;
  }
  .product-detail .van-popup--round .con-item .active {
    color: #FF6B01;
    border: 1px solid #FF6B01;
    background-color: rgba(255, 107, 1, .1);
  }
  .product-detail .van-popup--round .button {
    padding: 0.426667rem;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #fff;
  }
  .product-detail .van-popup--round .van-button {
    border: none;
    background: linear-gradient(to right, #dac5aa, #FF6B01);
  }
  .product-detail .van-popup--round::-webkit-scrollbar {
    width: 0 !important;
  }

</style>