<template>
  <div class="setting">
    <s-header :name="'我的资料'"></s-header>
    <div class="setting-body">
      <van-cell title="会员名" :value="username" size="large" />
      <van-cell title="手机号" is-link :value="tel" size="large" />
      <van-cell title="用户名" :value="name" size="large" />
    </div>
  </div>
</template>
<script>
  import sHeader from '@/components/SimpleHeader'
  export default {
    name: 'setting',
    data() {
      return {
        username: '医院端',
        tel: '18900001111',
        name: '我家口腔',
      }
    },
    components: {
      sHeader
    }
  }
</script>
<style lang="less">
  .setting {
    .setting-body {
      margin: 44px 10px 0;
      .van-cell {
        border-bottom: 1px solid #f0f0f0;
        .van-cell__title {
          color: rgb(37, 37, 37);
          font-size: 14px;
        }
        .van-cell__value {
          color: rgb(87, 84, 84);
        }
      }
    }
  }
</style>