import request from '@/utils/request'

// 公告
export function getNotice() {
  return request({
    url: '/customerapp/noticeInfo/getValidNoticeList',
    method: 'post'
  })
}

// 大类
export function getCategory() {
  return request({
    url: '/customerapp/productCategory/getValidCategorylist',
    method: 'post'
  })
}

// 小类
export function getCategoryDetail(query) {
  return request({
    url: '/customerapp/productCategory/getCategoryProductPageList',
    method: 'post',
    params: query
  })
}

// 获取医生大类
export function getDoctorCategory() {
  return request({
    url: '/customerapp/doctorInfo/getDoctorCategory',
    method: 'post'
  })
}

// 获取医生信息
export function getDoctorDetail(query) {
  return request({
    url: '/customerapp/doctorInfo/getDoctorPageList',
    method: 'post',
    params: query
  })
}

// 获取医院大类
export function getHospitalCategory() {
  return request({
    url: '/customerapp/supplierInfo/getHospitalCategory',
    method: 'post'
  })
}

// 获取医院信息
export function getHospitalDetail(query) {
  return request({
    url: '/customerapp/supplierInfo/getHospitalPageList',
    method: 'post',
    params: query
  })
}