import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { 
  prefix,  
  currentData,
  twoDecimals,
} from '@/utils/location'
// 引入 Vant
import Vant from 'vant'
import 'vant/lib/index.css'
// 桌面端适配
import '@vant/touch-emulator'
// 引入全局样式
import "./assets/css/base.less"
import 'lib-flexible/flexible'

//手机调试工具
// import VConsole from 'vconsole';
// let vconsole = new VConsole();
// export default vconsole;

import VueAMap from 'vue-amap'
Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
  // 高德的key
  key: '925d96d0fe3a5d9196108aee789cdb67',
  // 插件集合
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.Geolocation'],
  v: '1.4.4'
});


Vue.config.productionTip = false

/**
 * postcss 
 * px2rem-loader
 * 移动适配
 */
// function initRem() {
//   let cale = window.screen.availWidth > 750 ? 2 : window.screen.availWidth / 375
//   window.document.documentElement.style.fontSize = `${100 * cale}px`
// }

// window.addEventListener('resize', function() {
//   initRem()
// })

// 使用 Vant
Vue.use(Vant);

Vue.prototype.prefix = prefix; 
Vue.prototype.currentData = currentData;
Vue.prototype.twoDecimals = twoDecimals;
Array.prototype.remove = function(val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
}

new Vue({
  router,
  store,  //store:store 和router一样，将我们创建的Vuex实例挂载到这个vue实例中
  render: h => h(App)
}).$mount('#app')
