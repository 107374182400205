<template>
  <div class="ident">
    <van-image
      round
      fit="cover"
      width="5rem"
      height="5rem"
      src="https://hodo-1302958707.cos.ap-shanghai.myqcloud.com/loveteeth/login-wait-01.gif"
    />
    <p class="title">{{title}}</p>
  </div>
</template>
<script>
  import { loginCallback, register, getUserInfo } from '@/api/loginWait'
  import { setWX } from '@/utils/location'
  export default {
    data() {
      return {
        title: '登陆中...',
        type: 'loginCallback',
      };
    },
    // 组件创建完毕
    created() {
      this.onSubmit()
    },

    methods: {
      async onSubmit(values) {
        if (this.type == 'loginCallback') {
          
          //截取url 获取code
          let url = window.location.href.split("?")[1]
          let queryParams = {}
          if (url) {
            let queryString = url.substr(url.indexOf('?') + 1)
            let pairs = queryString.split('&')
            for(let pair of pairs) {
              let splitArray = pair.split('=')
              let key = splitArray[0]
              let value = splitArray[1]
              if (value.indexOf('#') > -1) {
                value = value.substring(0, value.indexOf('#'))
              }
              queryParams[key] = value
            }
          }

          //获取url参数
          let code = queryParams.code;
          let param = this.$route.query.param;
          let referencesId = this.$route.query.referencesId;
          let sign = this.$route.query.sign;
          const { data, resultCode } = await loginCallback({
            "code": code,
            "param": param,
            "referencesId": referencesId,
            "sign": sign
          })
          setWX('auth-SIGN-customer', data.authSign)
          setWX('auth-HODO-customer', data.authId)
          window.location.href = data.url
      }},
    },
  };
</script>
<style>
  .ident {
    text-align: center;
    margin: 100px 0;
  }
  .ident .bott {
    margin-top: 100px;
  }
  .ident .title{
    text-align: center;
    margin: 100px 0;
  }
</style>
