<template>
  <div class="anent-ident">
    <!-- <van-image
      round
      fit="cover"
      width="5rem"
      height="5rem"
      src="https://img01.yzcdn.cn/vant/cat.jpeg"
    /> -->
    <div class="login_box" >
      <p>{{title}}</p>
      <van-field
        v-model="userName"
        name="账号"
        clearable
        label="账号"
        placeholder="请输入账号"
        :rules="[{ required: true, message: '请输入账号' }]"
      />
      <van-field
        v-model="password"
        :type="passwordType"
        name="密码"
        clearable
        label="密码"
        placeholder="请输入密码"
        :rules="[{ required: true, message: '请输入密码' }]"
      >
        <template slot="right-icon">
          <span class="solts" @click="switchPasswordType">
                <van-icon name="eye" v-if="passwordType==='password'"/>
                <van-icon name="closed-eye" v-else />
          </span>
        </template>
      </van-field>
      <van-cell center title="记住密码">
        <template #right-icon>
          <van-switch v-model="switchChecked" size="24" />
        </template>
      </van-cell>
      <div class="bott">
        <van-button type="primary" @click="onSubmit">确认登录</van-button>
      </div>
    </div>
      
    
  </div>
</template>
<script>
  import { login, register, getUserInfo } from '@/api/login'
  import { setToken, getToken,setWX } from '@/utils/location'
  import {decrypt, encrypt} from '@/utils/jsencrypt'
  export default {
    data() {
      return {
        userName: 'agent_test',
        password: 'admin2401',
        passwordType: 'password',//输入框类型
        switchChecked: true ,//记住密码
        title: '我家口腔-医院版',
        type: 'login',
      };
    },
    created() {   
      this.getLocalStorage(this.logForm);
    },
    methods: {
      switchPasswordType() {//可见密码
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
      },
      getLocalStorage() {
        const info = JSON.parse("" + localStorage.getItem('loveteeth-agentinfo') + "");
        if(info !== null) {
          this.userName = (info.userName === undefined ? this.userName : info.userName)
          this.password = (info.password === undefined ? this.password : decrypt(info.password))
          this.switchChecked = (info.switchChecked === undefined ? true : Boolean(info.switchChecked))
        }
      },
      async onSubmit(values) {
        //存储密码
        if(this.switchChecked) {
          const info = {
            userName: this.userName,
            password: encrypt(this.password),
            switchChecked: this.switchChecked
          }
          localStorage.setItem('loveteeth-agentinfo', JSON.stringify(info));
        } else {
          localStorage.removeItem('loveteeth-agentinfo');
        }

        if (this.type == 'login') {
          const { data, resultCode } = await login({
            "userName": this.userName,
            "password": this.password
          })
          setToken(data)
          //setWX('X-HODO-Authorization-With', data.toeknValue)
          setWX(data.tokenKey, data.toeknValue)
          window.location.href = data.url
        }
      },
    },
  };
</script>
<style>
  .anent-ident {
    background-image: url("https://hodo-1302958707.cos.ap-shanghai.myqcloud.com/loveteeth/agent-login.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 800px;
    text-align: center;
    height: 100%;
  }
  .anent-ident .bott {
    margin-top: 100px;
  }
  .anent-ident .van-cell {
    background-color: #a6bddab3;
  }
  .anent-ident .login_box {
    width: 290px;
    height: 350px;
    /* background-color: #fff; */
    background-color: #cae7f5b3;
    border-radius: 5px;
  
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

</style>
