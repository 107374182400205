<template>
  <div class="about">
    <s-header :name="'关于我们'"></s-header>
    <div class="about-body">
      <van-divider :style="{ color: '#1baeae', borderColor: '#1baeae', fontSize: '20px', fontWeight: 500 }">关于</van-divider>
      <p>我家口腔平台，以高品质、高效率、高服务为宗旨，为客户提供口腔检测、护理等一站式解决方案。打造专业化口腔服务平台。</p>
      <p>上海口腔门诊不仅建立家庭医生式的“优”口腔服务网络平台，更为来自世界各地的精英牙医们提供了一个宽广的联动发展平台。医疗团队均由专精于各自领域的国际口腔医学界精英组成，汇聚中国、美国、日本、韩国等多国国际知名口腔专业医师，特聘上海第九人民医院、华西口腔医院、美国密歇根大学牙学院等多位口腔医学专家、教授亲诊，满足不同国籍会员的诊疗需求。</p>
    </div>
  </div>
</template>
<script>
  import sHeader from '@/components/SimpleHeader'
  export default {
    name: 'about',
    components: {
      sHeader
    }
  }
</script>
<style lang="less" scoped>
  .about {
    box-sizing: border-box;
    padding: 20px;
    .about-body {
      margin-top: 44px;
      font-size: 16px;
      a {
        color: #007fff;
      }
    }
  }
</style>