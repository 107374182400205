<template>
  <div class="create-order">
    <sHeader :name="'生成订单'"/>
    <!-- sys start -->
    <div class="order-item">
      <span>服务项目：</span>
      <van-card
        num="1"
        :price="info.serviceArr.advancePayment"
        :title="info.productName"
        :desc="info.introduction"
        :thumb="info.imgUrl"
      />
      <!-- 优惠券单元格 -->
      <!-- <van-coupon-cell
        :coupons="coupons"
        :chosen-coupon="chosenCoupon"
        @click="showCoupon = true"
      /> -->
      <!-- 优惠券列表 -->
      <!-- <van-popup v-model="showCoupon" round position="bottom" style="height: 90%; padding-top: 4px; overflow-y: hidden;">
        <van-coupon-list
          :show-exchange-bar="false"
          :coupons="coupons"
          :chosen-coupon="chosenCoupon"
          :disabled-coupons="disabledCoupons"
          @change="onChangeCoupon"
          @exchange="onExchangeCoupon"
        />
      </van-popup> -->
    </div>
    <!-- sys end -->
    <!-- address start -->
    <div class="order-item">
      <span>服务地址：</span>
      <div class="add-card" @click="editAddr">
        <div class="card-icon">
          <van-icon name="location-o" />
        </div>
        <div class="card-info" v-if="addrDefault != false">
          <div class="name ellipsis">{{info.addrInfo.userName}}</div>
          <span class="tel">{{info.addrInfo.userPhone}}</span>
          <div class="desc">{{info.addrInfo.userAddr}}</div>
        </div>
        <div class="card-info" v-else>
          <p>您还没有填写地址哦~</p>
        </div>
        <div class="card-right"><van-icon name="arrow" /></div>
      </div>
      <!-- 时间选择 -->
      <van-field
        readonly
        clickable
        name="datetimePicker"
        :value="timeText"
        is-link 
        label="服务时间"
        placeholder="点击选择时间"
        @click="showPicker = true"
      />
      <!-- 时间popoup -->
      <van-popup v-model="showPicker" :style="{width: '80%',height: '53%'}">
        <div class="title">请选择服务时间</div>
        <van-tree-select
          :items="items"
          :active-id.sync="activeId"
          :main-active-index.sync="activeIndex"
          @click-nav="getDate"
          @click-item="getTime"
        />
      </van-popup>
      <!-- 备注 -->
      <van-field
        readonly
        clickable
        name="remark"
        :value="remarkText"
        is-link 
        label="订单备注"
        placeholder="(选填)可填入特殊要求"
        @click="showRemark = true"
      />
      <van-popup round position="bottom" :style="{ height: '90%' }" v-model="showRemark">
        <div class="title">填写备注<van-button type="primary" plain size="small" @click="remarkConfirm">确定</van-button></div>
        <div class="remark-content">
          <van-field
            v-model="remarkText"
            rows="2"
            autosize
            label="留言"
            type="textarea"
            maxlength="50"
            placeholder="请输入留言"
            show-word-limit
          />
          <div class="file-box">
            <span>上传图片</span>
            <van-uploader 
              v-model="fileList" 
              multiple 
              :max-size="500 * 1024" 
              @oversize="onOversize"
              :before-read="onbeforeRead"
              :after-read="onAfterRead"
              :before-delete="onBeforeDelete" />
          </div>
        </div>
      </van-popup>
      <van-field name="switch" class="field-switch" label="需要发票">
        <template #input>
          <van-switch v-model="switchChecked" active-color="#FF6B01" size="20" />
        </template>
      </van-field>
    </div>
    <!-- address end -->
    <!-- pay -->
    <div class="order-item">
      <span>支付方式：</span>
      <van-field name="radio" class="field-switch" label="微信支付">
        <template #input>
          <van-checkbox v-model="checked" checked-color="#FF6B01"></van-checkbox>
        </template>
      </van-field>
    </div>
    <div class="hint-info">
      <p>温馨提示：</p>
      <p>  亲！请把费用支付到平台上，我们会为您提供发票，保修和专业的售后保障！</p>
    </div>
    <!-- button-bar start -->
    <van-submit-bar 
      :price="subPrice" 
      button-text="提交订单" 
      @submit="onSubmit" 
    />
    <!-- button-bar end -->
  </div>
</template>
<script>
  import sHeader from '@/components/SimpleHeader'
  import {getOrderInfo,uploadFile,createOrder} from '@/api/createOrder'
  import { Toast, Dialog } from 'vant'
  // 优惠券
  // const coupon = [
  //   {
  //     available: 1,
  //     condition: '无使用门槛\n最多优惠12元',
  //     reason: '',
  //     value: 150,
  //     name: '优惠券名称',
  //     startAt: 1489104000,
  //     endAt: 1514592000,
  //     valueDesc: '1.5',
  //     unitDesc: '元',
  //   }
  // ];

  export default {
    name: 'CreateOrder',
    data() {
      return {
        info: {},
        // 优惠券
        // showCoupon: false,
        // chosenCoupon: -1,
        // coupons: coupon,
        // disabledCoupons: coupon,
        // 地址
        addrDefault: false, // 是否有默认显示值
        addressInfo: {},
        // 时间
        showPicker: false,
        timeText: '', // 显示文本
        items: [], // 服务时间
        serviceTime: [{
          text: '08:00~10:00',
          id: 1
        }, {
          text: '10:00~12:00',
          id: 2
        }, {
          text: '12:00~14:00',
          id: 3
        }, {
          text: '14:00~16:00',
          id: 4
        }, {
          text: '16:00~18:00',
          id: 5
        }, {
          text: '18:00~20:00',
          id: 6
        }, {
          text: '20:00~22:00',
          id: 7
        }], // 服务时间段
        _serviceTime: [], // copy时间段
        activeId: 0, // 右侧选中时间
        activeIndex: 0, // 左侧选中时间
        // 备注
        showRemark: false,
        remarkText: '',
        // 上传图片
        fileList: [
          // { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' },
          // Uploader 根据文件后缀来判断是否为图片文件
          // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
          // { url: 'https://cloud-image', isImage: true },
        ],
        // 发票
        switchChecked: false,
        // 是否微信支付
        checked: true, 
        // 提交金额
        subPrice: 100,
        payInfo: {}
      }
    },
    components: {
      sHeader
    },
    mounted() {
      
    },
    created() {
      this.orderInfo()  
      this.init()
    },
    methods: {
      init() {
        this.info = localStorage.getItem('cartItem') && JSON.parse(localStorage.getItem('cartItem'))
        this.info.serviceArr.advancePayment = this.twoDecimals(this.info.serviceArr.advancePayment)
        this.subPrice = this.info.serviceArr.advancePayment * 100
        let fullDate = this.getFullWeek(this.currentData('yy-mm-dd'))
        let dataItems = []
        this._serviceTime = JSON.parse(JSON.stringify(this.serviceTime))
        
        for(let x of fullDate) {
          let _newD = this.currentData('mm-dd') // 当前日期
          var day2 = new Date()
          var _newtime = day2.getHours() + ':' + (day2.getMinutes() < 10 ? '0' + day2.getMinutes():day2.getMinutes()) // 当前时间
          if(_newD === x) { // 与当前日期匹配 进行disabled控制
            for(let y of this.serviceTime) {
              let spd = y.text.split('~');
              if(_newtime >= spd[0] && _newtime >= spd[1]) {
                y.disabled = true
              }
            }
          } else {
            this.serviceTime = this._serviceTime
          }
          let item = {
            text: x,
            id: x,
            className: 'my-class',
            children: this.serviceTime
          }
          dataItems.push(item)
        }
        this.items = dataItems
        if(this.showPicker === true) {
          this.info.month = this.items[this.activeIndex].id
        }
        this.timeText = this.info.serviceTime
        this.remarkText = this.info.remarkText

        // 获取选中地址
        // 刷新页面 判断当前
        if(this.$route.query.from == 'order') {
          this.addressInfo = JSON.parse(localStorage.getItem('checkAddr'))
          this.info.addrInfo = {
            addressId: this.addressInfo.id,
            userName: this.addressInfo.name,
            userPhone: this.addressInfo.tel,
            userAddr: this.addressInfo.address
          }
        }
      },
      // 获取订单信息
      orderInfo() {
        getOrderInfo().then(res => {
          this.info.orderNo = res.data.orderId
          this.addrDefault = res.data.address
          if(this.$route.query.from != 'order') {
            this.addressInfo = {
              addressId: res.data.addressId,
              userName: res.data.userName,
              userPhone: res.data.userPhone,
              userAddr: res.data.provinceName+' '+res.data.cityName+' '+res.data.regionName+' '+res.data.detailAddress
            }
            this.info.addrInfo = this.addressInfo
          }
        })
      },
      // 提交订单
      onSubmit() {
        if(this.timeText == undefined) {
          Toast('请选择服务时间！');
          return false;
        }
        if(this.info.addrInfo == undefined) {
          Toast('请选择地址！');
          return false;
        }
        let params = {
          addressId: this.info.addrInfo.addressId,
          advancePayPrice: this.info.serviceArr.advancePayment,
          attributeId: this.info.serviceArr.attributeId,
          orderId: this.info.orderNo,
          productId: this.info.serviceArr.productId,
          appointmentTimeRange: this.timeText,
          remarks: this.remarkText
        }
        createOrder(params).then(res => {
          this.payInfo.appId = res.data.appId
          this.payInfo.timeStamp = res.data.timeStamp
          this.payInfo.nonceStr = res.data.nonceStr
          this.payInfo.package = res.data.package
          this.payInfo.signType = res.data.signType
          this.payInfo.paySign = res.data.paySign
          if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                  document.addEventListener('WeixinJSBridgeReady',
                      this.onBridgeReady, false);
              } else if (document.attachEvent) {
                  document.attachEvent('WeixinJSBridgeReady',
                      this.onBridgeReady);
                  document.attachEvent('onWeixinJSBridgeReady',
                      this.onBridgeReady);
              }
              alert("请在微信上进行支付操作！");
              this.onBridgeReady();
          } else {
              this.onBridgeReady();
          }
        })
      },
      //向微信发起支付请求
      onBridgeReady() {
        WeixinJSBridge.invoke( 'getBrandWCPayRequest', {
            "appId": this.payInfo.appId,           //公众号名称,由商户传入
            "timeStamp": this.payInfo.timeStamp,   //时间戳,自1970年以来的秒数
            "nonceStr": this.payInfo.nonceStr,     //随机串
            "package": this.payInfo.package,
            "signType": this.payInfo.signType,     //微信签名方式：
            "paySign": this.payInfo.paySign        //微信签名
        },
        function(res){
            if(res.err_msg == "get_brand_wcpay_request:ok" ) {
              //alert('支付成功');
              window.location.href = 'http://tiantian.nat300.top/order?orderStatus=DFW'
            }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
              // 取消支付
              window.location.href = 'http://tiantian.nat300.top/order?orderStatus=101'
            }else if(res.err_msg == "get_brand_wcpay_request:fail"){
              // alert('支付失败');
              window.location.href = 'http://tiantian.nat300.top/order?orderStatus=101'
              WeixinJSBridge.call('closeWindow');
            } //使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok,但并不保证它绝对可靠。
        });
      },
      // 优惠券
      // onChangeCoupon(index) {
      //   this.showCoupon = false;
      //   this.chosenCoupon = index;
      // },
      // onExchangeCoupon(code) {
      //   this.coupons.push(coupon);
      // },
      // 编辑地址
      editAddr() {
        // Toast('编辑');
        let row = JSON.stringify(this.info)
        this.$router.push({ path: `address?from=order`})
      },
      // 获取当前日期
      getDate(index) {  // 获取日期
        this.info.month = this.items[index].id
        this.activeId = 0 // 还原勾选框
      },
      // 获取当前时间
      getTime(data) {  // 获取时间
        this.info.month = this.items[this.activeIndex].id
        this.timeText = this.info.month + ' ' + data.text
        this.info.serviceTime = this.info.month + ' ' + data.text
        this.showPicker = false;
      },
      // 文件大小
      onOversize(file) {
        Toast('文件大小不能超过 500kb');
      },
      // 文件读取前
      onbeforeRead(file) {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          Toast('请上传 jpg/png 格式图片');
          return false;
        }
        const result = this.fileList && this.fileList.some(item=>item.file.name===file.name)
        if(result) { Toast('请不要重复上传图片'); return false }

        // 文件上传
        var fd = new FormData();
        fd.append('cosFileName', 'orderImg');
        fd.append('file', file);
        fd.append('orderId', this.info.orderNo);
        fd.append('repeatType', 'N');
        fd.append('storagePath', 'customer/order');
        // let _param = {
        //   cosFileName: 'orderImg',
        //   file: file.name,
        //   ordrId: this.info.orderNo,
        //   repeatType: 'Y',
        //   storagePath: 'customer/order',
        // }
        uploadFile(fd).then(res => {
          console.log('success')
        })
        return true
      },
      // 文件读取完成后
      onAfterRead(file,detail) {
        // console.log(file)
        // this.info.imgList = this.fileList
      },
      // 文件删除之前
      onBeforeDelete(file,detail) {
        console.log(file, detail);
        Dialog.confirm({
          title: '文件',
          message: '确定要删除该文件？',
        }).then(() => {
          console.log(1);
          
        }).catch(() => {
          // on cancel
        });
      },
      // 提交(留言)
      remarkConfirm() {
        this.showRemark = false
        this.info.remarkText = this.remarkText
      },
      // 服务时间获取
      getFullWeek(param) {
        let base = new Date(param).getTime()
        let oneDay = 24 * 3600 * 1000;
        let date = [];
        let data = [Math.random() * 300];
        let time = new Date(base);
        // getFullYear() 年
        let newTime = time.getDate() && (time.getDate()<10?'0'+time.getDate():time.getDate())
        date.push([parseInt(time.getMonth() + 1) < 10 ? '0'+parseInt(time.getMonth() + 1): parseInt(time.getMonth() + 1), newTime].join('-'));
        for (let i = 1; i <7; i++) {
          let now = new Date(base += oneDay);
          let newDate = now.getDate() && (now.getDate() < 10 ? '0'+now.getDate(): now.getDate())
          date.push([parseInt(now.getMonth() + 1) <10?'0'+parseInt(now.getMonth() + 1):parseInt(now.getMonth() + 1), newDate].join('-'));
          data.push(Math.round((Math.random() - 0.5) * 20 + data[i - 1]));
        }
        let newdate = date
        return newdate;
      },

    }
  }
</script>
<style>
  .create-order {
    background-color: #f5f5f5;
  }
  .create-order .order-item:nth-child(3),.order-item:nth-child(4) {
    margin-top: 0 !important;
    padding: 0 10px 10px !important;
  }
  .create-order .order-item {
    margin-top: 44px;
    padding: 10px;
  }
  
  .create-order .van-card {
    background-color: #fff;
  }
  .create-order .order-item span {
    font-size: 14px;
    font-weight: bold;
  }
  .create-order .order-item .van-coupon-cell {
    margin: 8px 0 0;
  }
  .create-order .order-item .add-card {
    background-color: #fff;
    border-radius: 4px;
    margin: 8px 0;
    padding: 8px;
    display: flex;
  }
  .create-order .order-item .add-card .card-icon {
    border-radius: 20px;
    width: 8%;
    height: 26px;
    padding: 5px 4px 0px;
    text-align: center;
    margin: 5px;
    background: linear-gradient(to right, #ff6034, #FF6B01);
    color: #fff;
  }
  .create-order .order-item .add-card .card-info {
    width: 85%;
    padding: 0 4px;
  }
  .create-order .order-item .add-card .card-info p {
    font-size: 12px;
    color: #999;
  }
  .create-order .order-item .add-card .card-right {
    width: 7%;
    height: 38px;
    line-height: 44px;
    color: #999;
    font-size: 16px;
  }
  .create-order .order-item .add-card .name {
    width: 100px;
    color: #333;
    position:absolute;
    font-size: 14px;
    font-weight: bold;
  }
  .create-order .order-item .add-card .tel {
    color: #999;
    font-size: 10px;
    margin-left: 105px;
  }
  .create-order .order-item .add-card .desc {
    font-size: 10px;
    color: #333;
    line-height: 20px;
  }
  .create-order .order-item .title {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    color: #333;
    letter-spacing: 1px;
    position: fixed;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
  }
  .create-order .order-item .van-tree-select {
    margin-top: 50px;
  }
  .van-sidebar-item--select::before {
    background-color: #FF6B01;
  }
  .van-tree-select__item--active {
    color: #FF6B01;
  }
  .create-order input {
    text-align: right;
  }
  .create-order .van-popup--round .title {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: #333;
    letter-spacing: 1px;
    position: fixed;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
  }
  .create-order .van-popup--round .van-button {
    flex: 1;
    font-size: 22px;
    margin: 10px;
    color: #ccc;
    position: absolute;
    right: 0;
    color: #FF6B01;
    border: 1px solid #FF6B01;
  }
  .create-order .van-popup--round .remark-content {
    margin-top: 1.333333rem;
    background-color: #f5f5f5;
    padding: 10px;
  }
  .create-order .van-popup--round .file-box {
    margin-top: 10px;
    background-color: #fff;
    padding: 10px 16px 0;
  }
  .create-order .van-popup--round .file-box span {
    position: absolute;
    padding-top: 3px;
    color: #646566;
  }
  .create-order .van-popup--round .van-uploader {
    margin-top: 30px;
  }
  .create-order .order-item .field-switch .van-field__body {
    right: 5px;
    position: absolute;
  }
  .create-order .hint-info {
    background-color: #fff;
    padding: 10px 26px 100px;
    line-height: 1.5;
    font-size: 12px;
    color: #FF6B01;
  }
  .hint-info p:last-child {
    text-indent:2em;
  }
  .create-order .van-submit-bar {
    max-width: 750px; /*no */
    box-shadow: 0px -4px 5px rgba(0, 0, 0, .1);
    left: auto;
  }
  .create-order .van-submit-bar__price {
    color: #FF6B01
  }
  .create-order .van-submit-bar .van-button--danger {
    background: linear-gradient(to right, #ff6034, #FF6B01);
  }
</style>