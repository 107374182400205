<template>
  <div class="evaluate-box">
    <s-header :name="'评价'"></s-header>
    <van-empty description="店铺还没有评价" />
  </div>
</template>
<script>
  import sHeader from '@/components/SimpleHeader'
  export default {
    name: 'evaluate',
    data() {
      return {

      }
    },
    components: {
      sHeader
    }
  }
</script>
<style lang="less" scoped>
  .evaluate-box {
    .van-empty {
      margin-top: 44px;
    }
  }
</style>