import request from '@/utils/request'

export function listOrder(query) {
  return request({
    url: '/agentapp/orderInfo/selectOrderPageByStatus',
    method: 'post',
    params: query
  }) 
}

export function getOrderInfo(id) {
  return request({
    url: '/agentapp/orderInfo/selectByPrimaryKey?id=' + id,
    method: 'post',
  }) 
}

// 提交尾款信息
export function commitRestPayPrice(query) {
  return request({
    url: '/agentapp/orderInfo/commitRestPayPrice',
    method: 'post',
    params: query
  }) 
}