<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'App'
}
</script>
<style>
    body, html {
        height: 100%;
        padding: 0;
        margin: 0;
    }

    #app {
        font-family: Arial, Helvetica, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #171e33;
    }
</style>