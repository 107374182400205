import Vue from 'vue'
import Router from 'vue-router'

// 登录
import Login from '@/views/Login'
// 微信登陆回调等待页面
import LoginWait from '@/views/LoginWait'

// 首页
import Home from '@/views/Home'
// 产品搜索
import ProductList from '@/views/ProductList'
import ProductDetail from '@/views/ProductDetail'
// 生成订单
import CreateOrder from '@/views/CreateOrder'
// 扫码
import QRcode from '@/views/QRcode'

// 订单
import Order from '@/views/Order'
// 订单详情
import OrderDetail from '@/views/OrderDetail'

// 我的
import User from '@/views/User'

import Addr from '@/views/Address'
import AddrEdit from '@/views/AddressEdit'

import Category from '@/views/Category'

import Coupon from '@/views/Coupon'

import Evaluate from '@/views/Evaluate'

import About from '@/views/About'

import Setting from '@/views/Setting'

import Maps from '@/views/Map'
//医生详情
import DoctorDetail from '@/views/DoctorDetail'
//医院详情
import HospitalDetail from '@/views/HospitalDetail'

Vue.use(Router)

// 导出路由
export default new Router({
  mode: 'history',
  base: 'loveTeethAgent',
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/loginwait',
      name: 'loginwait',
      component: LoginWait
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/product-list',
      name: 'product-list',
      component: ProductList
    },
    {
      path: '/product/:productId',
      name: 'product',
      component: ProductDetail
    },
    {
      path: '/create-order',
      name: 'create-order',
      component: CreateOrder
    },
    {
      path: '/order',
      name: 'order',
      component: Order
    },
    {
      path: '/order-detail',
      name: 'order-detail',
      component: OrderDetail
    },
    {
      path: '/user',
      name: 'user',
      component: User
    },
    {
      path: '/address',
      name: 'addr',
      component: Addr
    },
    {
      path: '/address-edit',
      name: 'addr-edit',
      component: AddrEdit
    },
    {
      path: '/category',
      name: 'category',
      component: Category
    },
    {
      path: '/coupon',
      name: 'coupon',
      component: Coupon
    },
    {
      path: '/evaluate',
      name: 'evaluate',
      component: Evaluate
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/setting',
      name: 'setting',
      component: Setting
    },
    {
      path: '/map',
      name: 'map',
      component: Maps
    },
    {
      path: '/QRcode',
      name: 'QRcode',
      component: QRcode
    },
    {
      path: '/DoctorDetail/:doctorId',
      name: 'DoctorDetail',
      component: DoctorDetail
    },
    {
      path: '/HospitalDetail/:supplierId',
      name: 'HospitalDetail',
      component: HospitalDetail
    },
  ]
})

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

// const routes = [
//   {
//     path: '/',
//     name: 'about',
//     component: About
//   },
// ]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

// export default router

