<template>
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#1baeae">
    <van-swipe-item v-for="(item, index) in list" :key="index">
      <img :src="item.imgUrl" alt="" @click="goTo(item.link)"/>
    </van-swipe-item>
  </van-swipe>
</template>
<script>
  export default {
    name: 'Swiper',
    props: {
      list: {
        type: Array,
        default: null
      }
    },
    methods: {

      goTo(url) {
        window.open(url);
      }
    }
  }
</script>
<style>
  .my-swipe .van-swipe-item img {
    width: 100%;
    height: 100%;
  }
</style>