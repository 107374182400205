<template>
  <van-notice-bar left-icon="volume-o" :scrollable="false">
    <van-swipe
      vertical
      class="notice-swipe"
      :autoplay="3000"
      :show-indicators="false">
      <van-swipe-item v-for="(item, index) in noticeList" :key="index">{{item.noticeContent}}</van-swipe-item>
    </van-swipe>
  </van-notice-bar>
</template>
<script>
  export default {
    name: 'NoticeBar',
    props: {
      noticeList: {
        type: Array,
        default: null
      }
    }
  }
</script>
<style>
  .van-notice-bar {
    height: 37px;
    line-height: 37px;
    background-color: #fff;
    margin: 0px 0 4px;
  }
  .van-notice-bar i {
    font-size: 14px !important;
    margin-right: 10px;
  }
  .van-notice-bar .van-swipe__track--vertical {
    color: #666;
    line-height: 10px;
  }
  .van-notice-bar .van-swipe-item {
    line-height: 38px;
    font-size: 12px;
    /* color: #ed6a0c; */
  }
  .van-notice-bar .notice-swipe {
    height: 37px;
    line-height: 37px;
  }
</style>