<template>
  <div class="nav-bar">
    <ul class="nav-list">
      <router-link tag="li" class="nav-list-item active" to="./home">
        <van-icon name="home-o" />
        <span>首页</span>
      </router-link>
      <router-link tag="li" class="nav-list-item" to="./order">
        <van-icon name="orders-o" />
        <span>订单</span>
      </router-link>
      <router-link tag="li" class="nav-list-item" to="./user">
        <van-icon name="user-o" />
        <span>我的</span>
      </router-link>
    </ul>
  </div>
</template>
<script>
  import { getToken } from '../utils/location'
  export default {
    name: 'NavBar',
    data() {
      return {
      }
    },
    mounted() {
      const token = getToken()
      console.log(token);
      if(token) {
        // this.home = '/home'
      }
      const path = this.$route.path
      // if (token && path != '/home') {
      //   this.$store.dispatch('updateCart')
      // }
    },
    computed: {
      count () {
        return this.$store.state.cartCount
      }
    }
  }
</script>
<style>
  .nav-bar{
    position: fixed;
    max-width: 750px; /*no*/
    bottom: 0;
    width: 100%;
    padding: 5px 0;
    z-index: 1000;
    background: #fff;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    box-shadow: 0px -4px 5px rgba(0, 0, 0, .1);
  }
  .nav-list {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    padding: 0;
  }
  .nav-list-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
    color: #666;
  }
  .nav-list-item.router-link-active {
    color: #1baeae;
  }
  .nav-list i {
    text-align: center;
    font-size: 20px !important;
  }
  .nav-list span{
    font-size: 12px;
    line-height: 16px;
  }
      
</style>