import axios from 'axios'
import { Toast, Dialog } from 'vant'
import router from '../router'

import {getToken, removeToken,getWX,removeWX} from '@/utils/location'
import errCode from '@/utils/errCode'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

export default function request(config) {
  const instance = axios.create({
    baseURL: 'https://back.yixindoc.com/', //https://www.manyechina.com/loveTeethBack http://zhenxingchina.natapp1.cc/  http://localhost:8888/ http://localhost:7888/
    timeout: 5000
  })

  instance.interceptors.request.use(config => {
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers['X-Requested-With'] = getToken()
      config.headers['X-HODO-Authorization-With'] = getWX('X-HODO-Authorization-With')
      //config.headers['auth-SIGN-customer'] = getWX('auth-SIGN-customer')
      //config.headers['auth-HODO-customer'] = getWX('auth-HODO-customer')
    } else {
      removeToken();
      removeWX('X-HODO-Authorization-With');
      //removeWX('auth-SIGN-customer');
      //removeWX('auth-HODO-customer');
      router.push('/login');
    }
    // get请求映射参数
    if(config.method === 'get' && config.params) {
      let url = config.url + '?';
      for(const name of Object.keys(config.params)) {
        const value = config.params[name];
        const part = encodeURIComponent(name) + "=";
        if(value !== null && typeof(value) !== "undefined") {
          if(typeof value === 'object') {
            for(const key of Object.keys(value)) {
              let params = name + '[' + key + ']';
              const subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    } 
    return config
  }, err => {
    console.log(err);
  })

  instance.interceptors.response.use(res => {
    const code = res.data.code || 200;
    const msg = errCode[code] || res.data.message || errCode['default']
    console.log('request........................');
    if(code === 401) {
      Dialog.confirm({
        title: '系统提示',
        message: '登录状态已过期，您可以继续留在该页面，或者重新登录',
      }).then(() => {
        router.push('/login');
      }).catch(() => {
        // on cancel
      });
    } else if(code === 301) {
      Toast.fail(msg)
      return Promise.reject(new Error(msg))
    } else if(code === 500) {
      Toast.fail(msg)
      return Promise.reject(new Error(msg))
    } else if(code !== 200) {
      Toast.fail(msg)
      return Promise.reject('error')
    } else {
      return res.data
    }
  }, err => {
    console.log(err);
    let { message } = err;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if(message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      let code = message.substr(message.length - 3);
      if(code === '401') {
        Dialog.confirm({
          title: '系统提示',
          message: message,
        }).then(() => {
          router.push('/login');
        }).catch(() => {
          // on cancel
        });
      } else {
        Toast.fail(message)
      }
      return Promise.reject(err)
    }
    Toast.fail(message)
    return Promise.reject(err)
  })
  // 3.发送真正的网络请求
  return instance(config)
}

// axios.interceptors.response.use(res => {
//   if (typeof res.data !== 'object') {
//     Toast.fail('服务端异常！')
//     return Promise.reject(res)
//   }
//   if (res.data.resultCode != 200) {
//     if (res.data.message) Toast.fail(res.data.message)
//     if (res.data.resultCode == 416) {
//       router.push({ path: '/login' })
//     }
//     return Promise.reject(res.data)
//   }

//   return res.data
// })

// export default axios