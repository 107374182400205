<template>
  <div class="category-box">
    <s-header :name="'我家口腔'" :back="'/home'"></s-header>
    <van-notice-bar
      left-icon="volume-o"
      scrollable 
      :text="noticeText"
    />
    <van-tabs v-model="active" :swipeable="swipeable" @click="selectTopTabCategory">
      <van-tab v-for="(item, index) in optTab" :title="item" :key="index">
        <div v-if="index == 0" class="search-wrap" ref="searchWrap" >
          <ul class="nav-side">
            <li
              v-for="item in categoryData"
              :key="item.categoryId"
              v-text="item.categoryName"
              :class="{'active' : currentIndex == item.categoryId}"
              @click="selectMenu(item.categoryId)"
            ></li>
          </ul>
          <div class="search-content">
            <list-scroll :scroll-data="categoryDetailData">
              <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <div v-if='noData' style="margin: 20px; text-align: center; color: #ccc;">暂无数据</div>
                <template v-else>
                  <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :offset="20"
                    @load="onLoad">
                    <!-- 主体 -->
                    <div class="swiper-slide">
                      <div class="category-item" v-for="(prod, index) in categoryDetailData" :key="index" @click="chooseServe(prod)">
                        <van-icon :name="prod.icon" />
                        <div class="item-flex">
                          <p>{{prod.productName}}</p>
                          <p>{{prod.productDesc}}</p>
                          <p>{{prod.introduction}}</p>
                          <span>{{prod.sellPrice}}<span style="font-size: 12px;">元</span></span>
                        </div>
                      </div>
                    </div>
                  </van-list>
                </template>
              </van-pull-refresh>
            </list-scroll>
          </div>
        </div>
        <div v-else-if="index == 1" class="search-wrap" ref="searchWrap">
          <!-- <van-empty description="暂无医院信息" /> -->
          <ul class="nav-side">
            <li
              v-for="item in categoryData"
              :key="item.categoryId"
              v-text="item.categoryName"
              :class="{'active' : currentIndex == item.categoryId}"
              @click="selectMenu(item.categoryId)"
            ></li>
          </ul>
          <div class="search-content">
            <list-scroll :scroll-data="categoryDetailData">
              <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <div v-if='noData' style="margin: 20px; text-align: center; color: #ccc;">暂无数据</div>
                <template v-else>
                  <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :offset="20"
                    @load="onLoad">
                    <!-- 主体 -->
                    <div class="swiper-slide">
                      <div class="category-item" v-for="(hospital, index) in categoryDetailData" :key="index" @click="chooseServe(hospital)">
                        <van-icon :name="hospital.supplierImg" />
                        <div class="item-flex">
                          <p>{{hospital.supplierName}}</p>
                          <p>{{hospital.supplierInfo}}</p>
                          <span style="font-size: 12px;">特约医院</span>
                        </div>
                      </div>
                    </div>
                  </van-list>
                </template>
              </van-pull-refresh>
            </list-scroll>
          </div>
        </div>
        <div v-else-if="index == 2" class="search-wrap" ref="searchWrap">
          <!-- <van-empty description="暂无医生信息，敬请期待！" /> -->
          <ul class="nav-side">
            <li
              v-for="item in categoryData"
              :key="item.categoryId"
              v-text="item.categoryName"
              :class="{'active' : currentIndex == item.categoryId}"
              @click="selectMenu(item.categoryId)"
            ></li>
          </ul>
          <div class="search-content">
            <list-scroll :scroll-data="categoryDetailData">
              <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <div v-if='noData' style="margin: 20px; text-align: center; color: #ccc;">暂无数据</div>
                <template v-else>
                  <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :offset="20"
                    @load="onLoad">
                    <!-- 主体 -->
                    <div class="swiper-slide">
                      <div class="category-item" v-for="(doctor, index) in categoryDetailData" :key="index" @click="chooseServe(doctor)">
                        <van-icon :name="doctor.doctorImgUrl" />
                        <div class="item-flex">
                          <p>{{doctor.doctorName}}</p>
                          <p>{{doctor.doctorInfo}}</p>
                          <span style="font-size: 12px;">特约医生</span>
                        </div>
                      </div>
                    </div>
                  </van-list>
                </template>
              </van-pull-refresh>
            </list-scroll>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
  import sHeader from '@/components/SimpleHeader'
  import ListScroll from '@/components/ListScroll'
  import { getNotice,getCategory,getCategoryDetail,getDoctorCategory,getDoctorDetail,getHospitalCategory,getHospitalDetail } from '@/api/category'
  import { Toast } from 'vant'
  export default {
    name: 'category',
    data() {
      return {
        noticeText: '', // 公告
        swipeable: true,
        active: 0,
        optTab: ['分类', '医院', '医生'],
        loading: false, // 当loading为true时，转圈圈
        finished: false, // 数据是否请求结束，结束会先显示 -没有更多了-
        refreshing: false,
        noData: false, // 如果没有小类，显示暂无数据
        categoryData: [], // 大类数据
        currentIndex: 0,  // 当前下标
        categoryDetailData: [], // 小类数据
        tabIndex: 0,  // 上部tab下标 0分类 1医院 2医生
        // doctorData: [], // 医生大类数据
        // doctorDetailData: [], // 医生小类数据
        // hospitalData: [], // 医院大类数据
        // hospitalDetailData: [], // 医院小类数据
        total: 0, // 总条数
        pages: 0, // 总共几页 回调
        queryParams: {  // 分页数据
          pageSize: 10, // 每页几条
          pageNum: 1, // 第几页
          categoryId: 1, // 大类ID
        }
      }
    },
    components: {
      sHeader,
      ListScroll
    }, 
    created() {
      this.getNotices()
      this.backtop()
      this.getHeadList() // 大类
      this.getDetail()  // 小类
    }, 
    mounted() {
      // 设置视口高度
      let $screenHeight = document.documentElement.clientHeight
      this.$nextTick(() => {
        this.$refs.searchWrap[0].height = $screenHeight - 100 + 'px'
      })
    },
    methods: {
      //分类信息
      getHeadList() {
        getCategory().then(res => {
          this.categoryData = res.data
          this.currentIndex = res.data[0].categoryId
        })
      },
      getDetail() {
        getCategoryDetail(this.queryParams).then(res => {
          this.total = res.total
          this.pages = res.pages
          if(this.queryParams.pageNum !== 1) {
            this.categoryDetailData = this.categoryDetailData.concat(res.rows)
          } else {
            this.categoryDetailData = res.rows
          }
          this.refreshed = false
          this.refreshing = false
          this.loading = false
        })
      },
      //医生信息
      getDoctorHeadList() {
        getDoctorCategory().then(res => {
          this.categoryData = res.data
          this.currentIndex = res.data[0].doctorCategoryId
        })
      },
      getDoctorDetail() {
        getDoctorDetail(this.queryParams).then(res => {
          this.total = res.total
          this.pages = res.pages
          if(this.queryParams.pageNum !== 1) {
            this.categoryDetailData = this.categoryDetailData.concat(res.rows)
          } else {
            this.categoryDetailData = res.rows
          }
          this.refreshed = false
          this.refreshing = false
          this.loading = false
        })
      },

      //医院信息
      getHospitalHeadList() {
        getHospitalCategory().then(res => {
          this.categoryData = res.data
          this.currentIndex = res.data[0].categoryId
        })
      },
      getHospitalDetail() {
        getHospitalDetail(this.queryParams).then(res => {
          this.total = res.total
          this.pages = res.pages
          if(this.queryParams.pageNum !== 1) {
            this.categoryDetailData = this.categoryDetailData.concat(res.rows)
          } else {
            this.categoryDetailData = res.rows
          }
          this.refreshed = false
          this.refreshing = false
          this.loading = false
        })
      },

      //选择上方tab
      selectTopTabCategory(name, title) {
        console.log("param:"+name);
        console.log("param==:"+title);
        this.currentIndex = 0
        this.queryParams.categoryId = 1
        this.backtop()
        this.categoryDetailData = []
        this.queryParams.pageNum = 1
        this.pages = 0
        this.finished = false
        if(name == 0){
        this.tabIndex = 0  //tab 下标
          this.getHeadList()
          this.getDetail()
        }else if(name == 1){
        this.tabIndex = 1  //tab 下标
          this.getHospitalHeadList()
          this.getHospitalDetail()
        }else if(name == 2){
        this.tabIndex = 2  //tab 下标
          this.getDoctorHeadList()
          this.getDoctorDetail()
        }

      },

      //加载分类详情
      onLoadDetail(){
        if(this.tabIndex == 0){
          this.getDetail()
        }else if(this.tabIndex == 1){
          this.getHospitalDetail()
        }else if(this.tabIndex == 2){
          this.getDoctorDetail()
        }
      },

      // 滚动加载
      onLoad() {
        let _this = this
        setTimeout(() => {
          // 下拉刷新是否
          if (_this.refreshing) {
            this.categoryDetailData = [];
            this.refreshing = false;
          } 

          if (!_this.refreshing && _this.categoryDetailData.length < _this.total) {
            _this.queryParams.pageNum = _this.queryParams.pageNum + 1
            _this.loading = false;  
            this.onLoadDetail()
          }
        
           // 停止加载
          if(_this.categoryDetailData.length >= _this.total) {
            _this.finished = true;
            _this.loading = true;  
          }
          
        }, 2000)
      },
      // 刷新
      onRefresh() {
        setTimeout(() => {
          Toast('刷新成功');
          this.refreshing = true  
          this.loading = true
          this.reset()
        }, 1000);
      },
      reset() {
        this.categoryDetailData = []
        this.queryParams.pageNum = 1
        this.finished = false
        this.onLoadDetail()
      },
      goHome () {
        this.$router.push({ path: 'home' })
      },
      //分类
      selectMenu(index) {
        this.currentIndex = index
        this.queryParams.categoryId = index
        this.backtop()
        this.categoryDetailData = []
        this.queryParams.pageNum = 1
        this.pages = 0
        this.finished = false
        this.onLoadDetail()
      },
      // 产品详情
      chooseServe(item){
        if(this.tabIndex == 0){
          this.$router.push({ path: `product/${item.productId}` })
        }else if(this.tabIndex == 1){
          this.$router.push({ path: `HospitalDetail/${item.supplierId}` })
        }else if(this.tabIndex == 2){
          this.$router.push({ path: `DoctorDetail/${item.doctorId}` })
        }
      },
      // 公告
      getNotices() {
        getNotice().then(res => {
          this.noticeText = res.data[0].noticeContent
        })
      },
      backtop() {
        let timer = setInterval(() => {
            let top = window.pageYOffset
                || document.documentElement.scrollTop
                || document.body.scrollTop;
            if (0 >= top) {
                clearInterval(timer);
                return;
            }
            let scrollTop = top - Math.floor(top / 5);
            scrollTop = 5 >= scrollTop ? 0 : scrollTop;
            document.documentElement.scrollTop = document.body.scrollTop = scrollTop;
        }, 20);
      },
    }
  }
</script>
<style lang="less">
  .category-box {
    .van-notice-bar {
      position: fixed;
      max-width: 750px;/*no*/
      width: 100%;
      top: 44px;
      background-color: #fffbe8;
      font-size: 12px;
      z-index: 2;
    }
    .van-tabs {
      margin-top: 81px;
      .van-tabs__wrap {
        position: fixed;
        width: 100%;
        max-width: 750px;/*no*/
        z-index: 2;
        .van-tabs__line {
          background-color: #1baeae;
        }
      }
      .van-tab__pane {
        .search-wrap {
          width: 100%;
          background: #F8F8F8;
          padding-top: 54px;
          // .nav-side::-webkit-scrollbar {
          //   display: none;
          // }
          .nav-side {
            position: fixed;
            width: 28%;
            height: 520px;
            overflow: auto;
            box-sizing: border-box;
            background: #F8F8F8;
            padding-bottom: 100px;
            li {
              width: 100%;
              height: 56px;
              text-align: center;
              line-height: 56px;
              font-size: 14px;
              &.active {
                color: #1baeae;
                background: #fff;
              }
            }
          }
          .search-content {
            width: 72%;
            margin-left: 28%;
            height: 100%;
            padding: 0 10px;
            background: #fff;
            box-sizing: border-box;
            .swiper-slide {
              width: 100%;
              .category-item {
                display: flex;
                flex-wrap: wrap;
                flex-shrink: 0;
                width: 100%;
                border-bottom: 1px solid #f0f0f0;
                padding: 10px 0;
                .van-icon {
                  width: 45px;
                  height: 45px;
                  margin: 10px 5px;
                  padding: 12px;
                  border-radius: 50px;
                  background-color: #1baeae;
                  font-size: 22px !important;
                  color: #fff;
                }
                .item-flex {
                  margin-left: 10px;
                  width: 60%;
                  p:first-child {
                    font-size: 14px;
                    color: #333;
                  }
                  p {
                    font-size: 10px;
                    color: #999;
                    margin: 8px 0;
                  }
                  span {
                    color: #FF6B01;
                  }
                }
              }
            }
          }
        }
        .eval {
          padding-top: 54px;
          min-height: calc(100vh - 44px);
        }
      }
    }
  }
  
  @media only screen and (min-width: 750px){
    .category-box .van-tabs .van-tab__pane .search-wrap .nav-side {
      width: 146px;
    }
  }
  
</style>