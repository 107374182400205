<template>
  <div class="order-detail">
    <s-header :name="'订单详情'" @callback="close"></s-header>
    <van-tabs @change="onChangeTab" :color="'#1baeae'" :title-active-color="'#1baeae'" class="order-detail-tab" v-model="status">
      <van-tab title="订单详情" name="0">
        <div class="tab-item">
          <div class="order-status">
            <div class="status-item">
              <label v-if="oInfo.orderStatus == '101'"><van-icon name="orders-o" />订单预付款</label>
              <label v-if="oInfo.orderStatus == '201'"><van-icon name="orders-o" />订单待接单</label>
              <label v-if="oInfo.orderStatus == '301'"><van-icon name="orders-o" />订单待服务</label>
              <label v-if="oInfo.orderStatus == '401'"><van-icon name="orders-o" />订单服务中</label>
              <label v-if="oInfo.orderStatus == '501'"><van-icon name="orders-o" />订单待确认</label>
              <label v-if="oInfo.orderStatus == '601'"><van-icon name="orders-o" />订单待评价</label>
              <label v-if="oInfo.orderStatus == '701'"><van-icon name="orders-o" />订单已完成</label>
              <span>{{oInfo.createTime}}</span>
            </div>
            <!-- <div class="again-order">再来一单</div> -->
          </div>
          <div class="detail">
            <span>订单明细</span>
            <div class="item">
              <div class="item-wrap tit">我家口腔<van-icon name="arrow" /></div>
              <div class="item-wrap">
                <span>预付款 {{oInfo.productName}}( {{oInfo.attributeName}}) -预付款</span>
                <span>* 1</span>
                <span>￥{{oInfo.advancePayPrice}}</span>
              </div>
              <div class="item-wrap">
                <span>订单尾款 ￥{{oInfo.restPayPrice}} - 优惠￥ 0</span>
                <span>总计 ￥{{oInfo.restPayPrice + oInfo.advancePayPrice}}</span>
              </div>
              <!-- <div class="again-order">再来一单</div> -->
            </div>
          </div>
          <div class="other-detail">
            <span>其他信息</span>
            <div class="oth-box">
              <div class="oth-item">
                <label>订单号</label>
                <span>{{oInfo.orderId}}</span>
              </div>
              <div class="oth-item">
                <label>接单时间</label>
                <span>{{oInfo.acceptTime}}</span>
              </div>
              <div class="oth-item">
                <label>服务时间</label>
                <span>{{oInfo.serviceTime}}</span>
              </div>
              <div class="oth-item">
                <label>下单人</label>
                <span>{{oInfo.consigneeName}}</span>
              </div>
              <div class="oth-item">
                <label>手机</label>
                <span>{{oInfo.consigneeMobile}}</span>
              </div>
              <div class="oth-item">
                <label>服务地址</label>
                <span>{{oInfo.consigneeAddress}} {{oInfo.consigneeRegion}} {{oInfo.consigneeDetailAddress}}</span>
              </div>
              <div class="oth-item">
                <label>服务人员编号</label>
                <span>{{oInfo.agentId}}</span>
              </div>
              <div class="oth-item">
                <label>支付方式</label>
                <span>微信</span>
              </div>
              <div class="oth-item">
                <label>支付流水号</label>
                <span>{{oInfo.advancePayId}}</span>
              </div>
              <div class="oth-item">
                <label>备注信息</label>
                <div>{{oInfo.remarks}}</div>
              </div>
            </div>
          </div>
          <label v-if="oInfo.orderStatus == '401'">
            <div class="other-detail">
              <span>尾款金额  --请在服务结束时输入</span>
              <div class="oth-box">
                <div class="oth-item">
                  <label>请输入尾款</label>
                  <div>
                    <input  type="number" placeholder="请输入尾款金额" v-model="orderInfo.restPayPrice" 
                    name="restPayPrice" id="restPayPrice"  onkeyup="this.value=this.value.replace(/\D/g,'')" /><label>元</label>
                  </div>
                </div>
                <div style="text-align: right;margin-top:10px;">
                  <van-button style="background:#FF8c00;color:red"  @click.stop="toCommitRestPayPrice(oInfo.orderId, oInfo.orderStatus)">
                    &nbsp;&nbsp; 提交尾款金额 &nbsp;&nbsp;
                  </van-button>
                </div>
              </div>
            </div>
          </label>
        </div>
      </van-tab>
      <van-tab title="订单状态" name="1">
        <div class="tab-item">
          <van-steps direction="vertical" :active="active" active-color="#1baeae">
            <van-step>
              <h3>订单提交成功</h3>
              <p>时间：{{oInfo.createTime}}</p>
              <p>订单号：{{oInfo.orderId }}</p>
            </van-step>
             <van-step>
              <h3>预付款</h3>
              <p>请在订单提交成功后30分钟内完成支付</p>
            </van-step>
            <van-step>
              <h3>待上门</h3>
              <p>时间：{{oInfo.advancePayTime}}</p>
              <p>正在为您分配医院，请保持电话畅通</p>
            </van-step>
            <van-step>
              <h3>待服务</h3>
              <p>时间：{{oInfo.acceptTime}}</p>
              <p>已经安排医院，请在预约时间段抵达</p>
            </van-step>
            <van-step>
              <h3>服务中</h3>
              <p>时间：{{oInfo.serviceTime}}</p>
              <p>服务中</p>
            </van-step>
            <van-step>
              <h3>尾款支付</h3>
              <p>时间：{{oInfo.finishTime}}</p>
              <p>请您支付订单剩余尾款</p>
            </van-step>
            <van-step>
              <h3>订单完成</h3>
              <p>祝您生活愉快！</p>
            </van-step>
          </van-steps>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
  import { Dialog } from 'vant'
  import sHeader from '@/components/SimpleHeader'
  import { getOrderInfo,commitRestPayPrice } from '@/api/order'
  export default {
    name: 'order-detail',
    data() {
      return {
        status: 0,
        active: 0,
        oid: '', // id
        oInfo: {}, // 回调
        orderInfo: {
          orderId:"",//订单信息
          restPayPrice:""//尾款金额
        }, // 订单信息
      }
    },
    components: {
      sHeader
    },
    created() {
      this.oid = this.$route.query.id
      this.init()
    },
    methods: {
      init() {
        getOrderInfo(this.oid).then(res => {
          this.oInfo = res.data
          let _status = res.data.orderStatus
          if(_status == '101') {
            this.active = 1
          } else if(_status == '201') {
            this.active = 2
          } else if(_status == '301') {
            this.active = 3
          } else if(_status == '401') {
            this.active = 4
          } else if(_status == '501') {
            this.active = 5
          } else if(_status == '601' || _status == '701') {
            this.active = 6
          }
        })
      },
      onChangeTab() {},
      close() {
        Dialog.close()
      },

      toCommitRestPayPrice(orderId, orderStatus){
        this.orderInfo.orderId = orderId
        commitRestPayPrice(this.orderInfo).then(res => {
          if(res.code == 0 ){
              alert("已经成功确认订单！");
            }
            //刷新页面 --暂时没有作用
            this.$router.push({ path: `order` })
        })
      }
    }
    
  }
</script>
<style lang="less" scoped>
  .order-detail {
    background-color: #f5f5f5;
    .van-tabs--line .van-tabs__wrap {
        position: fixed;
        height: 1.173333rem;
        width: 100%;
        z-index: 2;
      }
    .order-detail-tab {
      margin-top: 44px;
      
  
      .van-tabs__nav {
        position: fixed;
        width: 100%;
        z-index: 2;
      }
      .tab-item {
        padding: 10px;
        .van-steps h3 {
          font-size: 14px;
        }
      }
      .order-status {
        background-color: #fff;
        padding: 10px 10px;
        .status-item {
          margin: 2px 8px 8px;
          justify-content: space-between;
          display: flex;
          label {
            color: #333;
            font-size: 14px;
            i {
              width: 22px;
              height: 22px;
              padding-top: 3px;
              margin-right: 5px;
              border-radius: 10px;
              text-align: center;
              background-color: #FF6B01;
              color: #fff;
              position: relative;
            }
          }
          span {
            color: #999;
            font-size: 12px;
            margin-top: 6px;
          }
        }
        .again-order {
          border-top: 1px solid #ccc;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 13px;
        }
      }
      .detail {
        margin-top: 10px;
        .item {
          background-color: #fff;
          padding: 10px 10px;
          margin-top: 5px;
          .tit {
            font-size: 14px;
            line-height: 20px !important;
            i {
              padding-top: 2px;
            }
          }
          .item-wrap {
            border-bottom: 1px dashed #ccc;
            justify-content: space-between;
            display: flex;
            height: 30px;
            line-height: 30px;
            padding: 2px;
          }
          .again-order {
            text-align: center;
            height: 35px;
            line-height: 35px;
            color: #FF6B01;
            font-size: 13px;
          }
        }
        span {
          font-size: 12px;
          color: #999;
        }
      }
      .other-detail {
        margin-top: 10px;
        .oth-box {
          margin-top: 5px;
          .oth-item {
            background-color: #fff;
            border-bottom: 1px solid #f5f5f5;
            padding: 0 15px;
            // height: 35px;
            line-height: 35px;
            justify-content: space-between;
            display: flex;
            label,span {
              font-size: 13px;
            }
            div {
              width: 80%;
              padding: 10px 0;
              line-height: 18px;
              font-size: 13px;
              color: #999;
              text-align: right;
            }
          }
          
        }
        span {
          font-size: 12px;
          color: #999;
        }
      }

    }
  }
</style>