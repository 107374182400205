<template>
  <div class="address-edit-box">
    <s-header :name="`${type == 'add' ? '新增地址' : '编辑地址'}`"></s-header>
    <van-address-edit
      :area-list="areaList"
      :address-info="addressInfo"
      show-delete
      show-set-default
      show-search-result
      :search-result="searchResult"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
      @click-area="setAddr"
    />
    <!-- <div class="address-edit">
      <div class="van-address-edit__fields">
        <van-field
          v-model="addressInfo.name"
          name="姓名"
          clearable
          label="姓名"
          placeholder="收货人姓名"
          :rules="[{ required: true, message: '请填写收货人姓名' }]"
        />
        <van-field
          v-model="addressInfo.tel"
          name="电话"
          clearable
          label="电话"
          placeholder="收货人手机号"
          :rules="[{ required: true, message: '请填写收货人手机号' }]"
        />
        <van-field
          name="地区"
          readonly 
          label="地区"
          right-icon="location-o"
          placeholder="选择省/市/区"
          @click="setAddr"
        />
        <van-field
          v-model="addressInfo.addressDetail"
          name="详细地址"
          clearable
          label="详细地址"
          placeholder="街道门牌、楼层房间号等信息"
          :rules="[{ required: true, message: '请填写详细地址' }]"
        />
      </div>
      <van-cell center title="设为默认收货地址">
        <template #right-icon>
          <van-switch v-model="switchChecked" size="24" />
        </template>
      </van-cell>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">保存</van-button>
        <van-button round block plain type="default" >删除</van-button>
      </div>
    </div> -->
  </div>
</template>
<script>
  import { Toast } from 'vant'
  import sHeader from '@/components/SimpleHeader'
  import {addAddress, editAddress, delAddress, getDetail} from '@/api/address'
  import { tdist } from '@/utils/location'
  export default {
    name: 'address-edit',
    data() {
      return {
        type: 'add',
        from: '',
        areaList: {
          province_list: {},
          city_list: {},
          county_list: {}
        },
        searchResult: [],
        addressId: '',
        addressInfo: {},
        switchChecked: true,
      }
    },
    components: {
      sHeader
    },
    async mounted() {
      // 省市区列表构造
      let _province_list = {}
      let _city_list = {}
      let _county_list = {}
      tdist.getLev1().forEach(p => {
        _province_list[p.id] = p.text
        tdist.getLev2(p.id).forEach(c => {
          _city_list[c.id] = c.text
          tdist.getLev3(c.id).forEach(q => _county_list[q.id] = q.text)
        })
      })
      this.areaList.province_list = _province_list
      this.areaList.city_list = _city_list
      this.areaList.county_list = _county_list

      const { addressId, type, from } = this.$route.query
      this.addressId = addressId
      this.type = type
      this.from = from || ''
      if(type == 'edit') {
        getDetail(this.addressId).then(res => {
          const data = {
            provinceName: res.data.consigneeProvinceName,
            cityName: res.data.consigneeCityName,
            regionName: res.data.consigneeRegionName
          }
          let _areaCode = ''
          const province = tdist.getLev1()
          Object.entries(this.areaList.county_list).forEach(([id, text]) => {
            // 先找出当前对应的区
            if (text == data.regionName) {
              // 找到区对应的几个省份
              const provinceIndex = province.findIndex(item => item.id.substr(0, 2) == id.substr(0, 2))
              // 找到区对应的几个市区
              const cityItem = Object.entries(this.areaList.city_list).filter(([cityId, cityName]) => cityId.substr(0, 4) == id.substr(0, 4))[0]
              // 对比找到的省份和接口返回的省份是否相等，因为有一些区会重名
              if (province[provinceIndex].text == data.provinceName && cityItem[1] == data.cityName) {
                _areaCode = id
              }
            }
          })
          let _isDefault = false;
          if(res.data.addressDefault == 0) {
            _isDefault = true 
          } else {
            _isDefault = false
          }
          this.addressInfo = {
            id: res.data.addressId,
            name: res.data.consigneeName,
            tel: res.data.consigneeMobile,
            province: res.data.consigneeProvinceName,
            city: res.data.consigneeCityName,
            county: res.data.consigneeRegionName,
            addressDetail: res.data.consigneeDetailAddress,
            areaCode: _areaCode,
            isDefault: !!_isDefault
          };
        })
      }
    },
    created() {
    },
    methods: {
      async onSave(content) {
        const params = {
          consigneeName: content.name,
          consigneeMobile: content.tel,
          consigneeProvinceName: content.province,
          consigneeCityName: content.city,
          consigneeRegionName: content.county,
          consigneeDetailAddress: content.addressDetail,
          addressDefault: content.isDefault ? 0 : 1,
        }
        if (this.type == 'edit') {
          params['addressId'] = this.addressId
          editAddress(params).then(res => {
            Toast('修改成功')
            setTimeout(() => {
              this.$router.push({ path: `address?from=${this.from}` })
            }, 1500)
          })
        } else {
          addAddress(params).then(res => {
            Toast('保存成功')
            setTimeout(() => {
              this.$router.push({ path: `address?from=${this.from}` })
            }, 1500)
          })
        }
      },
      onDelete() {
        delAddress(this.addressId).then(res => {
          Toast('删除成功')
            setTimeout(() => {
              this.$router.push({ path: `address?from=${this.from}` })
            }, 1500)
        })
      },
      // onChangeDetail(val) {
      //   if (val) {
      //     this.searchResult = [
      //       {
      //         name: '黄龙万科中心',
      //         address: '杭州市西湖区',
      //       },
      //     ];
      //   } else {
      //     this.searchResult = [];
      //   }
      // },
      setAddr() {}
    }
  }
</script>
<style lang="less">
  @import "../assets/css/base.less";
  .address-edit-box {
    margin-top: 44px;
    .address-edit {
      padding: 10px;
      .van-button--info {
        background-color: #1baeae;
        border-color: #1baeae;
        margin-bottom: 12px;
      }
      .van-switch--on {
        background: @primary;
      }
      .van-cell--center {
        margin-top: 12px;
      }
    }
    .van-address-edit {
      padding: 10px;
      .van-button--danger {
        background-color: #1baeae;
        border-color: #1baeae;
        margin-bottom: 12px;
      }
      .van-switch--on {
        background: @primary;
      }
      .van-cell--center {
        margin-top: 12px;
      }
    }
  }
</style>