import request from '@/utils/request'

// 公告
export function getOrderInfo() {
  return request({
    url: '/customerapp/orderInfo/getOrderId',
    method: 'post'
  })
}

// 影像件
export function uploadFile(data) {
  return request({
    url: '/customerapp/orderInfo/uploadImageFile',
    method: 'post',
    data: data
  })
}

// 创建订单
export function createOrder(query) {
  return request({
    url: '/customerapp/orderInfo/saveOrderInfo',
    method: 'post',
    params: query
  })
}
